import ToastStack from 'components/desktop/shared/toast-stack';
import { IconCoin } from 'components/icons/IconCoin';
import { useToast } from 'hooks';
import Link from 'next/link';
import { Fragment, MouseEvent } from 'react';

const ChallengeCard = () => {
  const { toasts, addToast, removeToast } = useToast();

  const click = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    addToast('information', 'Chức năng đang được hoàn thiện!');
  };

  return (
    <Fragment>
      <div className='relative'>
        <div className='group flex flex-col overflow-hidden rounded-[8px] transition-all duration-[500ms] ease-in-out shadow-7'>
          <div className='relative'>
            <div className='h-[200px] overflow-hidden'>
              <img
                src='/dummy/challenge.webp'
                alt='/dummy/challenge.webp'
                loading='lazy'
                className='h-full w-full object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
              />
            </div>
          </div>
          <div className='flex flex-col items-start space-y-[12px] p-[12px] bg-paper'>
            <span className='line-clamp-2 w-full min-h-[40px] font-[600] text-[16px] leading-[20px]'>
              [ToptoToes] Giảm 33% - SĂN DEAL HỜI HÈ HỨNG KHỞI
            </span>
            <div className='flex items-center space-x-[4px] rounded-[4px] bg-secondary h-[26px] px-[6px]'>
              <IconCoin className='h-[13px] w-[13px]' />
              <span className='font-[600] truncate text-primary'>{(12000).toLocaleString()}</span>
            </div>
            <Link href='#' className='font-[500] truncate text-primary' onClick={click}>
              Nhận thử thách
            </Link>
          </div>
        </div>
      </div>
      <ToastStack toasts={toasts} removeToast={removeToast} />
    </Fragment>
  );
};

export default ChallengeCard;
