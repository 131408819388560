import { IconArrowLeft, IconArrowRight } from 'components/icons';
import { ReactNode } from 'react';
import { Autoplay, FreeMode, Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';

interface Props {
  id: string;
  autoplay?: boolean;
  freeMode?: boolean;
  loop?: boolean;
  spaceBetween?: number;
  wrapperClassname?: string;
  titleComponent?: ReactNode;
  iconComponent?: ReactNode;
  viewMoreComponent?: ReactNode;
  cardListComponent?: ReactNode;
}

const CardList = (props: Props) => {
  const {
    id,
    autoplay,
    freeMode,
    loop,
    spaceBetween,
    wrapperClassname,
    titleComponent,
    iconComponent,
    viewMoreComponent,
    cardListComponent,
  } = props;

  return (
    <div
      id={id}
      className='container mx-auto flex min-w-[1052px] items-center justify-center space-x-[16px] px-[48px]'
    >
      <button
        id={`${id}-swiper-back`}
        type='button'
        className='!flex items-center justify-center min-w-[38px] h-[38px] rounded-full bg-transparent shadow-22 transition duration-[200ms] ease-in-out hover:bg-paper disabled:bg-transparent disabled:opacity-50'
      >
        <IconArrowLeft className='h-[28px] w-[28px] text-text2' />
      </button>
      <div className={wrapperClassname}>
        <div className='flex items-center justify-between pr-[16px]'>
          <div className='flex items-center space-x-[8px]'>
            {iconComponent && (
              <div className='rounded-r-[50px] bg-primary px-[23px] py-[7px]'>{iconComponent}</div>
            )}
            {titleComponent}
          </div>
          {viewMoreComponent}
        </div>
        <div className='px-[16px]'>
          <Swiper
            threshold={10}
            draggable
            slidesPerView='auto'
            loop={loop}
            autoplay={autoplay}
            freeMode={freeMode}
            spaceBetween={spaceBetween}
            navigation={{ prevEl: `#${id}-swiper-back`, nextEl: `#${id}-swiper-forward` }}
            modules={[FreeMode, Navigation, Autoplay]}
            className='!m-[-16px] !px-[8px]'
          >
            {cardListComponent}
          </Swiper>
        </div>
      </div>
      <button
        id={`${id}-swiper-forward`}
        type='button'
        className='!flex items-center justify-center min-w-[38px] h-[38px] rounded-full bg-transparent shadow-22 transition duration-[200ms] ease-in-out hover:bg-paper disabled:bg-transparent disabled:opacity-50'
      >
        <IconArrowRight className='h-[28px] w-[28px] text-text2' />
      </button>
    </div>
  );
};

export default CardList;
