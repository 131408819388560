import CardList from 'components/desktop/shared/card-list';
import { map } from 'lodash';
import { SwiperSlide } from 'swiper/react';
import DealCard from './deal-card';

const DealCardList = () => {
  const deals = Array(3).fill({});

  return (
    <div
      className='relative flex h-[592px] bg-[#f2f7ff] rounded-tl-[150px] rounded-br-[150px]'
      style={{
        backgroundImage: "url('/images/dots-vertical.svg'), url('/images/dots-horizontal.svg')",
        backgroundPosition: '4% 28%, 96% 74%',
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: '62px 117px, 117px 62px',
      }}
    >
      <CardList
        id='deal-card-list'
        spaceBetween={0}
        wrapperClassname='min-w-[944px] xl:min-w-[1200px] flex flex-col bg-paper rounded-[16px] space-y-[16px] py-[16px] drop-shadow-26'
        titleComponent={
          <h2 className='text-[20px] font-[600] leading-[23px] pl-[16px]'>Deal hời</h2>
        }
        cardListComponent={map(deals, (_, dealIndex) => (
          <SwiperSlide
            key={dealIndex}
            className='min-w-[464px] max-w-[464px] px-[8px] py-[16px] xl:min-w-[395px] xl:max-w-[395px]'
          >
            <DealCard />
          </SwiperSlide>
        ))}
      />
    </div>
  );
};

export default DealCardList;
