import { gt, map, size } from 'lodash';
import Link from 'next/link';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const Banner = () => {
  const mocks = Array(1).fill({});

  return (
    <Swiper
      draggable
      autoplay
      pagination
      loop={gt(size(mocks), 1)}
      modules={[Autoplay, Pagination]}
      className='w-full master'
    >
      {map(mocks, (_, mockIndex) => (
        <SwiperSlide key={mockIndex}>
          <Link target='_blank' rel='noopener noreferrer' href='#'>
            <img
              src='/images/banners/master-home-banner.webp'
              alt='/images/banners/master-home-banner.webp'
              loading='lazy'
              className='w-full h-full object-cover'
            />
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Banner;
