import CardList from 'components/desktop/shared/card-list';
import { map } from 'lodash';
import { SwiperSlide } from 'swiper/react';
import ChallengeCard from './challenge-card';

const ChallengeCardList = () => {
  const challenges = Array(4).fill({});

  return (
    <div className='relative flex h-[496px]'>
      <CardList
        id='challenge-card-list'
        spaceBetween={0}
        wrapperClassname='min-w-[944px] xl:min-w-[1200px] flex flex-col bg-paper rounded-[16px] space-y-[16px] py-[16px] drop-shadow-26'
        titleComponent={
          <h2 className='text-[20px] font-[600] leading-[23px] pl-[16px]'>Thử thách nhận quà</h2>
        }
        cardListComponent={map(challenges, (_, challengeIndex) => (
          <SwiperSlide
            key={challengeIndex}
            className='min-w-[309.5px] max-w-[309.5px] px-[8px] py-[16px] xl:min-w-[296.3px] xl:max-w-[296.3px]'
          >
            <ChallengeCard />
          </SwiperSlide>
        ))}
      />
    </div>
  );
};

export default ChallengeCardList;
