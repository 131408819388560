import ToastStack from 'components/desktop/shared/toast-stack';
import { IconCoin } from 'components/icons/IconCoin';
import { useToast } from 'hooks';
import Link from 'next/link';
import { Fragment, MouseEvent } from 'react';

const LuckyDraw = () => {
  const { toasts, addToast, removeToast } = useToast();

  const click = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    addToast('information', 'Chức năng đang được hoàn thiện!');
  };

  return (
    <Fragment>
      <div
        className='relative flex h-[802px]'
        style={{
          backgroundImage: "url('/images/dots-vertical.svg'), url('/images/sky-gradient.webp')",
          backgroundPosition: '96% 74%, center',
          backgroundRepeat: 'no-repeat, no-repeat',
          backgroundSize: '62px 117px, cover',
        }}
      >
        <div className='container mx-auto flex min-w-[1052px] items-center justify-center space-x-[16px] px-[48px]'>
          <div className='min-w-[944px] xl:min-w-[1200px] flex flex-col space-y-[16px] py-[16px] drop-shadow-26'>
            <div className='flex items-center justify-between pr-[16px]'>
              <div className='flex items-center space-x-[8px]'>
                <h2 className='text-[20px] font-[600] leading-[23px] pl-[16px]'>
                  Vòng quay may mắn
                </h2>
              </div>
            </div>
            <div className='px-[16px]'>
              <Link href='#' className='relative' onClick={click}>
                <div className='group flex flex-col overflow-hidden rounded-[8px] transition-all duration-[500ms] ease-in-out'>
                  <div className='relative'>
                    <div className='h-[500px] overflow-hidden'>
                      <img
                        src='/dummy/lucky-draw.webp'
                        alt='/dummy/lucky-draw.webp'
                        loading='lazy'
                        className='h-full w-full object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
                      />
                    </div>
                  </div>
                  <div className='flex flex-col p-[12px] bg-paper'>
                    <div className='flex items-center space-x-[12px]'>
                      <span className='font-[500]'>Giải thưởng</span>
                      <div className='flex items-center space-x-[4px] rounded-[4px] bg-secondary h-[26px] px-[6px]'>
                        <IconCoin className='h-[13px] w-[13px]' />
                        <span className='font-[600] truncate text-primary'>
                          {(12000).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ToastStack toasts={toasts} removeToast={removeToast} />
    </Fragment>
  );
};

export default LuckyDraw;
