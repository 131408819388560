import ToastStack from 'components/desktop/shared/toast-stack';
import { IconPercentageOutlined } from 'components/icons';
import { IconCoin } from 'components/icons/IconCoin';
import { useToast } from 'hooks';
import Link from 'next/link';
import { Fragment, MouseEvent } from 'react';

const DealCard = () => {
  const { toasts, addToast, removeToast } = useToast();

  const click = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    addToast('information', 'Chức năng đang được hoàn thiện!');
  };

  return (
    <Fragment>
      <Link href='#' className='relative' onClick={click}>
        <div className='group flex flex-col overflow-hidden rounded-[8px] transition-all duration-[500ms] ease-in-out shadow-7'>
          <div className='relative'>
            <div className='h-[220px] overflow-hidden'>
              <img
                src='/dummy/deal.webp'
                alt='/dummy/deal.webp'
                loading='lazy'
                className='h-full w-full object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
              />
            </div>
          </div>
          <div className='flex flex-col space-y-[12px] p-[12px] bg-paper'>
            <span className='line-clamp-2 min-h-[40px] font-[600] text-[16px] leading-[20px]'>
              [ToptoToes] Giảm 33% - SĂN DEAL HỜI HÈ HỨNG KHỞI
            </span>
            <div className='flex min-h-[18px] w-full items-center space-x-[8px] text-alert2'>
              <IconPercentageOutlined className='h-[16px] w-[16px]' />
              <span className='font-[600] truncate'>2.000 VNĐ</span>
            </div>
            <div className='flex min-h-[26px] items-center justify-between space-x-[24px]'>
              <div className='flex justify-center items-center space-x-[12px]'>
                <span className='font-[500]'>Hoặc mua với</span>
                <div className='flex items-center space-x-[4px] rounded-[4px] bg-secondary h-[26px] px-[6px]'>
                  <IconCoin className='h-[13px] w-[13px]' />
                  <span className='font-[600] truncate text-primary'>{(200).toLocaleString()}</span>
                </div>
              </div>
              <span className='font-[500] truncate text-text2'>130 đã bán</span>
            </div>
          </div>
        </div>
      </Link>
      <ToastStack toasts={toasts} removeToast={removeToast} />
    </Fragment>
  );
};

export default DealCard;
