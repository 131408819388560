import ToastStack from 'components/desktop/shared/toast-stack';
import { SITE_MAP } from 'globalConstants';
import { useToast } from 'hooks';
import { isEqual, map } from 'lodash';
import Link from 'next/link';
import { Fragment, MouseEvent } from 'react';

interface Module {
  icon: string;
  label: string;
  href: string;
}

const ModuleCardList = () => {
  const { toasts, addToast, removeToast } = useToast();
  const modules: Module[] = [
    {
      icon: '/icons/modules/townhouse.svg',
      label: 'Bất động sản',
      href: `/${SITE_MAP.REAL_ESTATE}`,
    },
    {
      icon: '/icons/modules/shopping.svg',
      label: 'Mua sắm',
      href: `/${SITE_MAP.E_COMMERCE.INDEX}`,
    },
    {
      icon: '/icons/modules/health.svg',
      label: 'Sức khỏe',
      href: `/${SITE_MAP.E_COMMERCE.INDEX}/${SITE_MAP.E_COMMERCE.PRODUCT.INDEX}/suc-khoe`,
    },
    {
      icon: '/icons/modules/flower.svg',
      label: 'Làm đẹp',
      href: `/${SITE_MAP.E_COMMERCE.INDEX}/${SITE_MAP.E_COMMERCE.PRODUCT.INDEX}/lam-dep`,
    },
    {
      icon: '/icons/modules/map.svg',
      label: 'Bản đồ',
      href: `/${SITE_MAP.MAP}`,
    },
    {
      icon: '/icons/modules/finance.svg',
      label: 'Tài chính',
      href: `/${SITE_MAP.DISCOVERY.INDEX}/tai-chinh`,
    },
    {
      icon: '/icons/modules/network.svg',
      label: 'Mạng lưới',
      href: `/${SITE_MAP.MY_NETWORK.INDEX}`,
    },
    {
      icon: '/icons/modules/valuation.svg',
      label: 'Định giá',
      href: `/${SITE_MAP.VALUATION}`,
    },
    {
      icon: '/icons/modules/blog.svg',
      label: 'Tin tức',
      href: `/${SITE_MAP.BLOG.INDEX}`,
    },
    {
      icon: '/icons/modules/be.svg',
      label: 'Be',
      href: `/${SITE_MAP.DISCOVERY.INDEX}/be`,
    },
  ];

  const clickCard = (event: MouseEvent<HTMLAnchorElement>, service: Module) => {
    if (isEqual(service.href, '#')) {
      event.preventDefault();
      addToast('information', 'Chức năng đang được hoàn thiện!');
    }
  };

  return (
    <Fragment>
      <div className='container mx-auto min-w-[1052px] px-[54px] screen-1140:px-[98px] xl:px-[48px] screen-1366:px-[83px]'>
        <div className='flex flex-col py-[16px] bg-paper drop-shadow-26 rounded-[16px] space-y-[20px]'>
          <h2 className='text-[20px] font-[600] leading-[23px] pl-[16px]'>Khám phá danh mục</h2>
          <div className='flex flex-wrap mx-[-16px] px-[24px]'>
            {map(modules, (module, moduleIndex) => (
              <Link
                key={moduleIndex}
                href={module.href}
                target='_blank'
                rel='noopener noreferrer'
                className='flex flex-col items-center space-y-[12px] w-1/5 xl:w-[10%] p-[8px]'
                onClick={(event) => clickCard(event, module)}
              >
                <img
                  src={module.icon}
                  alt={module.icon}
                  loading='lazy'
                  className='h-[56px] w-[56px] object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
                />
                <span className='font-[500] text-[16px] leading-[20px] text-center'>
                  {module.label}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <ToastStack toasts={toasts} removeToast={removeToast} />
    </Fragment>
  );
};

export default ModuleCardList;
