import Footer from 'components/desktop/shared/footer';
import { Fragment } from 'react';
import Header2 from '../shared/header2';
import Banner from './banner';
import ChallengeCardList from './challenge-card-list';
import DealCardList from './deal-card-list';
import LuckyDraw from './lucky-draw';
import ModuleCardList from './module-card-list';

const MasterHome = () => (
  <Fragment>
    <Header2 />
    <main className='flex min-h-[1000px] min-w-[1052px] flex-col bg-paper'>
      <div className='flex flex-col space-y-[28px] '>
        <Banner />
        <ModuleCardList />
        <DealCardList />
        <ChallengeCardList />
        {/* <ServiceCardList /> */}
        <LuckyDraw />
      </div>
    </main>
    <Footer />
  </Fragment>
);

export default MasterHome;
